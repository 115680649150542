import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "RubyKaigi 2017 にメドレーが Ruby Sponsor として参加しました",
  "date": "2017-09-28T03:02:29.000Z",
  "slug": "entry/2017/09/28/120000",
  "tags": ["medley"],
  "hero": "./2017_09_28.png",
  "heroAlt": "rubykaigi 2017"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは！開発本部のエンジニア・`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/54029"
      }}>{`新居`}</a>{`です。`}</p>
    <p>{`メドレーは 9/18〜20 に開催された、`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2017/"
      }}>{`RubyKaigi 2017`}</a>{`の Ruby Sponsor を務めさせていただきました。（9/15〜17 に開催された`}<a parentName="p" {...{
        "href": "https://iosdc.jp/2017/"
      }}>{`iOSDC JAPAN 2017`}</a>{`に引き続きの協賛です！メドレーでは今年からテックカンファレンスへの協賛を積極的に行っています）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928115311.jpg",
        "alt": "f:id:medley_inc:20170928115311j:plain",
        "title": "f:id:medley_inc:20170928115311j:plain"
      }}></img></p>
    <p>{`イベント当日は、弊社から CTO の平山、エンジニアの平木と宍戸、そして新居の 4 人が参加しました。今回はそのときの様子などをレポートしたいと思います。`}</p>
    <h1>{`会場の様子`}</h1>
    <p>{`今年は広島県の広島国際会議場での開催でした。`}</p>
    <p>{`広島国際会議場は平和記念公園の敷地内にあり、近くには原爆ドームや広島城などもあるので RubyKaigi のついでに観光しちゃおうなんて人も多かったのではないでしょうか。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922103927.jpg",
        "alt": "f:id:dev-medley:20170922103927j:plain",
        "title": "f:id:dev-medley:20170922103927j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922103952.jpg",
        "alt": "f:id:dev-medley:20170922103952j:plain",
        "title": "f:id:dev-medley:20170922103952j:plain"
      }}></img></p>
    <p>{`会場の一角には今回の RubyKaigi 参加者がどこから来たかを掲示するボードが設置されていました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922104014.jpg",
        "alt": "f:id:dev-medley:20170922104014j:plain",
        "title": "f:id:dev-medley:20170922104014j:plain"
      }}></img></p>
    <p>{`日本以外からもたくさんの人が参加しており RubyKaigi の注目度の高さが伺えます。`}</p>
    <p>{`そして隣にあったスポンサーボードの MEDLEY ロゴをしっかり確認して、CTO 平山とパシャリ！！！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922104049.jpg",
        "alt": "f:id:dev-medley:20170922104049j:plain",
        "title": "f:id:dev-medley:20170922104049j:plain"
      }}></img></p>
    <h1>{`ブースの様子`}</h1>
    <p>{`続いてブースの様子を！
ブースの仕上がりはこんな感じで、メドレーコーポレートカラーの赤が目立って良い感じでした！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928115453.jpg",
        "alt": "f:id:medley_inc:20170928115453j:plain",
        "title": "f:id:medley_inc:20170928115453j:plain"
      }}></img></p>
    <p>{`両サイドにはメドレーの事業概要と、メディアなどからも注目されることが多いオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」を紹介するポスターを配置、写真中央のモニターでは、スマートフォンや PC で医師の診療が受けられるという CLINICS のサービス概要がわかる紹介動画を流し、会社全体やプロダクトについて説明できる準備を整えました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922104208.jpg",
        "alt": "f:id:dev-medley:20170922104208j:plain",
        "title": "f:id:dev-medley:20170922104208j:plain"
      }}></img></p>
    <p>{`ノベルティは「MEDLEY ステッカー」「MEDLEY うちわ」「MEDLEY 絆創膏」の 3 種類を用意しました。`}</p>
    <p>{`1 日目は台風一過で気温が上昇し、暑さをしのぐため MEDLEY うちわが大活躍してくれました！`}</p>
    <p>{`MEDLEY 絆創膏はデザインのかわいさと医療系ならではということもありとても好評でした！参加者の中には靴擦れしている人や小さい擦り傷を負ってる人もいて、MEDLEY 絆創膏が参加者のお役に立てたのではないでしょうか。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922104242.jpg",
        "alt": "f:id:dev-medley:20170922104242j:plain",
        "title": "f:id:dev-medley:20170922104242j:plain"
      }}></img></p>
    <p>{`ブース前で意気込む 3 人！`}</p>
    <p>{`ちなみに T シャツはメドレー特製の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/76141"
      }}>{`バリュー T シャツ（左）とカレッジロゴ T シャツ（中央と右）`}</a>{`を着用しています。`}</p>
    <p>{`写真では見えませんが、バリュー T シャツにはメドレーのバリュー（行動規範）のひとつである「中央突破」の文字が入っています。`}</p>
    <p>{`両 T シャツ共、目を留めてくれた参加者からはかわいいという声もいただけて、こちらも好評でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928115637.jpg",
        "alt": "f:id:medley_inc:20170928115637j:plain",
        "title": "f:id:medley_inc:20170928115637j:plain"
      }}></img></p>
    <p>{`午後の 15:20~15:50 の Afternoon Break では大勢の参加者がブースゾーンに。`}</p>
    <p>{`実際にブースに足を運んでいただいた参加者とお話していると、メドレーや CLINICS のことを知らない人がほとんどでしたが、メドレーが医療系のサービスを提供している会社であることや、CLINICS というオンライン診療アプリを開発していることをお話すると興味を持っていただけることが多かったように思います。`}</p>
    <p>{`「今度病院で見かけたら使ってみます！」という声をいただいたり、参加者自身の医療体験を元に CLINICS というサービスについて意見を交わしたりと、メドレーエンジニアと参加者が密にコミュニケーションをとれる良い機会になりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928115734.jpg",
        "alt": "f:id:medley_inc:20170928115734j:plain",
        "title": "f:id:medley_inc:20170928115734j:plain"
      }}></img></p>
    <p>{`大盛況です！`}</p>
    <h1>{`CTO 平山の発表`}</h1>
    <p>{`そして 3 日目の最後の Keynote 前には Ruby スポンサーの PR 枠があり、弊社の CTO 平山が発表しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928120004.jpg",
        "alt": "f:id:medley_inc:20170928120004j:plain",
        "title": "f:id:medley_inc:20170928120004j:plain"
      }}></img></p>
    <p>{`発表では、「医療ヘルスケア分野の課題を解決する」というミッションのもとメドレーが 4 つの事業を行っていること、その中のひとつである CLINICS のプロダクトについて、「医療 x IT への挑戦」にむけて`}<a parentName="p" {...{
        "href": "https://amp.review/2017/09/01/medley/"
      }}>{`医療従事者とエンジニア・デザイナーが協力してプロダクト開発を行う体制`}</a>{`などを紹介しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922104626.jpg",
        "alt": "f:id:dev-medley:20170922104626j:plain",
        "title": "f:id:dev-medley:20170922104626j:plain"
      }}></img></p>
    <p>{`最終日 3 日目の最後ということもありお疲れの方も多かったと思いますが、会場を笑いで沸かすシーンもあり、メドレーと、そのプロダクトのことを少しでも多くの人に知っていただくとても良い機会になったのではないでしょうか。`}</p>
    <p>{`当日の発表資料はこちらからどうぞ。`}</p>
    <iframe id="talk_frame_407759" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/7029a5f722844d96a7ef0ea793b488e2" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/company-and-product-information-rubykaigi-2017">speakerdeck.com</a></cite>
    <h1>{`周辺散策の様子（おまけ）`}</h1>
    <p>{`最後におまけということで周辺散策の様子を！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922120050.jpg",
        "alt": "f:id:dev-medley:20170922120050j:plain",
        "title": "f:id:dev-medley:20170922120050j:plain"
      }}></img></p>
    <p>{`まずは原爆ドーム。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928120937.jpg",
        "alt": "f:id:medley_inc:20170928120937j:plain",
        "title": "f:id:medley_inc:20170928120937j:plain"
      }}></img></p>
    <p>{`続いて広島城跡へ。御門橋。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922120112.jpg",
        "alt": "f:id:dev-medley:20170922120112j:plain",
        "title": "f:id:dev-medley:20170922120112j:plain"
      }}></img></p>
    <p>{`歩みを進める 3 人。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170928/20170928120718.jpg",
        "alt": "f:id:medley_inc:20170928120718j:plain",
        "title": "f:id:medley_inc:20170928120718j:plain"
      }}></img></p>
    <p>{`そして天守閣。原爆で倒壊したためコンクリート建築として復元されたようです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922115844.jpg",
        "alt": "f:id:dev-medley:20170922115844j:plain",
        "title": "f:id:dev-medley:20170922115844j:plain"
      }}></img></p>
    <p>{`近くには広島護国神社。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922115739.jpg",
        "alt": "f:id:dev-medley:20170922115739j:plain",
        "title": "f:id:dev-medley:20170922115739j:plain"
      }}></img></p>
    <p>{`メドレー`}<a parentName="p" {...{
        "href": "https://info.medley.jp/entry/2017/05/23/171859"
      }}>{`恒例`}</a>{`の？参拝！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170922/20170922115919.jpg",
        "alt": "f:id:dev-medley:20170922115919j:plain",
        "title": "f:id:dev-medley:20170922115919j:plain"
      }}></img></p>
    <p>{`美味しい広島のお好み焼きもいただきました！`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`今回はメドレーとして初の RubyKaigi 参加でした。`}</p>
    <p>{`ブース運営などへの不安もありましたが、実際に当日を迎えてみると、参加者のみなさんとお話でき、メドレーのことを少しでも多くの人に認知していただくとても良い機会だったと思います。`}</p>
    <p>{`まだまだメドレーのことをご存知ない方も多かったですが、実際にお会いして話してみると、医療というリアル産業をインターネットの力で変えていくという面白さに興味を持っていただけたことが多かったなという印象を受けました。`}</p>
    <p>{`良いプロダクトを作ることも大切ですが、医療分野でのプロダクト作りの醍醐味や産み出せる価値などを多くのエンジニア・デザイナーに知ってもらえるよう、こうしたテックカンファレンスの場などで発信し続けていくことも大切だなあと改めて実感しました。`}</p>
    <p>{`弊社で「医療 x IT への挑戦」に取り組みたいエンジニアのみなさんを心からお待ちしております！興味がある方は、こちらの「話を聞いてみたい」からご連絡ください。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの最新情報 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley">www.wantedly.com</a></cite>
    <p>{`開発本部の雰囲気をもっと知りたい方は、こちらからどうぞ。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      